module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Max Resources","short_name":"Max Resources","description":"Stream the most talked about shows and movies featuring the worlds of HBO, the DC Universe, Harry Potter, and more on Max.","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"standalone","icon":"./static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1be691205a6b0e8c22a98be7a8eb2d9e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
